import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import Modal from 'react-modal';
import styles from './css/Config.module.css';

const Config = ({ token, isAdmin }) => {
  const [clients, setClients] = useState([]);
  const [linkedClients, setLinkedClients] = useState([]);
  const [newClientName, setNewClientName] = useState('');
  const [newXmlUrl, setNewXmlUrl] = useState('');
  const [newCreditBalance, setNewCreditBalance] = useState(''); // New state for credit balance
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState(''); // Success or error
  const [editClientName, setEditClientName] = useState('');
  const [editXmlUrl, setEditXmlUrl] = useState('');
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentEditClient, setCurrentEditClient] = useState(null);
  const [deleteClientName, setDeleteClientName] = useState('');
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [editCreditBalance, setEditCreditBalance] = useState(''); // State for editing credit balance

  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const targetUsername = queryParams.get('username') || '';  // Get the target username from the query parameter

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const configResponse = await axios.get(`${process.env.REACT_APP_API_URL}/config`, {
          headers: { Authorization: `Bearer ${token}` },
          params: { username: targetUsername }  // Fetch data for the specified user
        });
        setLinkedClients(configResponse.data.linked_clients || []);

        if (isAdmin) {
          const clientResponse = await axios.get(`${process.env.REACT_APP_API_URL}/client-feeds`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          setClients(clientResponse.data);
        }
      } catch (err) {
        console.error('Failed to fetch data:', err);
        setMessage('Failed to fetch data');
        setMessageType('error');
      }
    };

    fetchClients();
  }, [token, targetUsername, isAdmin]);

  const handleAddClient = async (e) => {
    e.preventDefault();
    try {
      const newClient = { client_name: newClientName, xml_url: newXmlUrl, credit_balance: newCreditBalance };
      await axios.post(`${process.env.REACT_APP_API_URL}/client-feeds`, newClient, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setClients([...clients, newClient]);
      setNewClientName('');
      setNewXmlUrl('');
      setNewCreditBalance(''); // Reset the credit balance field
      setMessage('Client added successfully');
      setMessageType('success');
      setIsAddModalOpen(false);
    } catch (err) {
      console.error('Failed to add client:', err);
      if (err.response && err.response.data.message) {
        setMessage(err.response.data.message);
      } else {
        setMessage('Failed to add client');
      }
      setMessageType('error');
    }
  };

  const handleEditClient = (client) => {
    setEditClientName(client.client_name);
    setEditXmlUrl(client.xml_url);
    setEditCreditBalance(client.credit_balance); // Set the credit balance for editing
    setCurrentEditClient(client);
    setIsEditModalOpen(true);
  };
  

  const handleSaveEditClient = async (e) => {
    e.preventDefault();
    try {
      const updatedClient = { 
        new_client_name: editClientName, 
        xml_url: editXmlUrl, 
        credit_balance: editCreditBalance // Include credit balance in the update
      };
      await axios.put(`${process.env.REACT_APP_API_URL}/client-feeds/${currentEditClient.client_name}`, updatedClient, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      const updatedClients = clients.map(client =>
        client.client_name === currentEditClient.client_name ? { ...client, client_name: editClientName, xml_url: editXmlUrl, credit_balance: editCreditBalance } : client
      );
  
      setClients(updatedClients);
      setIsEditModalOpen(false);
      setMessage('Client updated successfully');
      setMessageType('success');
    } catch (err) {
      console.error('Failed to update client:', err);
      if (err.response && err.response.data.message) {
        setMessage(err.response.data.message);
      } else {
        setMessage('Failed to update client');
      }
      setMessageType('error');
    }
  };
  

  const handleDeleteClient = (clientName) => {
    setDeleteClientName(clientName);
    setIsDeleteModalOpen(true);
  };

  const confirmDeleteClient = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/client-feeds/${deleteClientName}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setClients(clients.filter(client => client.client_name !== deleteClientName));
      setLinkedClients(linkedClients.filter(linkedClient => linkedClient !== deleteClientName));
      setMessage('Client deleted successfully');
      setMessageType('success');
      setIsDeleteModalOpen(false);
    } catch (err) {
      console.error('Failed to delete client:', err);
      setMessage('Failed to delete client');
      setMessageType('error');
    }
  };

  const handleAssignClient = async (clientName) => {
    try {
      const updatedLinkedClients = [...linkedClients, clientName];
      await axios.post(`${process.env.REACT_APP_API_URL}/admin/assign-client`, { username: targetUsername, client_name: clientName }, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setLinkedClients(updatedLinkedClients);
      setMessage('Client linked successfully');
      setMessageType('success');
    } catch (err) {
      console.error('Failed to link client:', err);
      setMessage('Failed to link client');
      setMessageType('error');
    }
  };

  const handleUnassignClient = async (clientName) => {
    try {
      const updatedLinkedClients = linkedClients.filter(client => client !== clientName);
      await axios.post(`${process.env.REACT_APP_API_URL}/admin/unassign-client`, { username: targetUsername, client_name: clientName }, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setLinkedClients(updatedLinkedClients);
      setMessage('Client unlinked successfully');
      setMessageType('success');
    } catch (err) {
      console.error('Failed to unlink client:', err);
      setMessage('Failed to unlink client');
      setMessageType('error');
    }
  };

  const filteredClients = clients.filter(client => 
    client.client_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    client.xml_url.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className={styles.configPage}>
      <div className={styles.backIcon} onClick={() => navigate(isAdmin ? '/admin' : '/client')}>
        <i className="fas fa-arrow-left"></i>
      </div>
      <div className={styles.configContainer}>
        <h2>Configuration</h2>
        {message && (
          <div className={messageType === 'error' ? styles.errorMessage : styles.successMessage}>
            {message}
          </div>
        )}

        {!isAdmin && linkedClients.length === 0 && (
          <div className={styles.noClientsMessage}>
            <p>You don't have any assigned clients on your account. Please contact an Admin.</p>
          </div>
        )}
        <div className={styles.section}>
          <h3>Linked Clients</h3>
          <ul>
            {linkedClients.map((clientName, index) => (
              <li key={index}>{clientName}</li>
            ))}
          </ul>
        </div>

        {isAdmin && (
          <div className={styles.adminSection}>
            <div className={styles.section}>
              <h3>Assign Clients to User</h3>
              <input
                type="text"
                placeholder="Search clients and xml urls..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className={styles.searchInput}
              />
              <div className={styles.clientList}>
                <ul>
                  {filteredClients.map((client, index) => (
                    <li key={index} className={styles.clientItem}>
                      <span>{client.client_name}</span>
                      <div className={styles.clientActions}>
                        <button onClick={() => handleEditClient(client)} className={`${styles.button} ${styles.editButton}`}>
                          <i className="fas fa-edit"></i>
                        </button>
                        <button onClick={() => handleDeleteClient(client.client_name)} className={`${styles.button} ${styles.deleteButton}`}>
                          <i className="fas fa-trash"></i>
                        </button>
                        {!linkedClients.includes(client.client_name) ? (
                          <button onClick={() => handleAssignClient(client.client_name)} className={`${styles.button} ${styles.linkButton}`}>
                            <i className="fas fa-link"></i>
                          </button>
                        ) : (
                          <button onClick={() => handleUnassignClient(client.client_name)} className={`${styles.button} ${styles.unlinkButton}`}>
                            <i className="fas fa-unlink"></i>
                          </button>
                        )}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <button onClick={() => setIsAddModalOpen(true)} className={styles.addClientButton}>Add New Client</button>
          </div>
        )}
      </div>

      <Modal
        isOpen={isAddModalOpen}
        onRequestClose={() => setIsAddModalOpen(false)}
        className={styles.modal}
        overlayClassName={styles.overlay}
      >
        <h2>Add New Client</h2>
        <form onSubmit={handleAddClient}>
          <div className={styles.formGroup}>
            <label>Client Name</label>
            <input
              type="text"
              placeholder="Client Name"
              value={newClientName}
              onChange={(e) => setNewClientName(e.target.value)}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label>XML URL</label>
            <input
              type="text"
              placeholder="XML URL"
              value={newXmlUrl}
              onChange={(e) => setNewXmlUrl(e.target.value)}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label>Credit Balance</label>
            <input
              type="number"
              placeholder="Credit Balance"
              value={newCreditBalance}
              onChange={(e) => setNewCreditBalance(e.target.value)}
              required
            />
          </div>
          <button type="submit" className={styles.submitButton}>Add Client</button>
          <button onClick={() => setIsAddModalOpen(false)} className={styles.cancelButton}>Cancel</button>
        </form>
      </Modal>

      <Modal
        isOpen={isEditModalOpen}
        onRequestClose={() => setIsEditModalOpen(false)}
        className={styles.modal}
        overlayClassName={styles.overlay}
      >
        <h2>Edit Client</h2>
        <form onSubmit={handleSaveEditClient}>
          <div className={styles.formGroup}>
            <label>Edit Client Name</label>
            <input
              type="text"
              placeholder="Client Name"
              value={editClientName}
              onChange={(e) => setEditClientName(e.target.value)}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label>Edit XML URL</label>
            <input
              type="text"
              placeholder="XML URL"
              value={editXmlUrl}
              onChange={(e) => setEditXmlUrl(e.target.value)}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label>Edit Credit Balance</label>
            <input
              type="number"
              placeholder="Credit Balance"
              value={editCreditBalance}
              onChange={(e) => setEditCreditBalance(e.target.value)}
              required
            />
          </div>
          <button type="submit" className={styles.submitButton}>Save Changes</button>
          <button onClick={() => setIsEditModalOpen(false)} className={styles.cancelButton}>Cancel</button>
        </form>
      </Modal>

      <Modal
        isOpen={isDeleteModalOpen}
        onRequestClose={() => setIsDeleteModalOpen(false)}
        className={styles.modal}
        overlayClassName={styles.overlay}
      >
        <h2>Are you sure you want to delete this client?</h2>
        <p>{deleteClientName}</p>
        <button onClick={confirmDeleteClient} className={styles.submitButton}>Yes, Delete</button>
        <button onClick={() => setIsDeleteModalOpen(false)} className={styles.cancelButton}>Cancel</button>
      </Modal>
    </div>
  );
};

export default Config;
