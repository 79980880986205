import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import styles from './css/Register.module.css'; // Adjust the path based on your folder structure

const Register = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [adminPassword, setAdminPassword] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/register`, { 
        username, 
        password, 
        admin_password: isAdmin ? adminPassword : null 
      });
      setMessage(response.data.message);
    } catch (err) {
      setMessage(err.response.data.message);
    }
  };

  return (
    <div className={styles.appContainer}>
      <div className={styles.registerContainer}>
        <h2>Register</h2>
        <button 
          className={styles.backButton} 
          onClick={() => navigate('/admin')}
        >
          Back
        </button>
        <form onSubmit={handleSubmit}>
          <div className={styles.formGroup}>
            <label>Username</label>
            <input 
              type="text" 
              placeholder="Username" 
              value={username} 
              onChange={(e) => setUsername(e.target.value)} 
              required 
            />
          </div>
          <div className={styles.formGroup}>
            <label>Password</label>
            <input 
              type="password" 
              placeholder="Password" 
              value={password} 
              onChange={(e) => setPassword(e.target.value)} 
              required 
            />
          </div>
          <label>Should this account be admin?</label>
          <div className={styles.formGroup, styles.adminCheckboxContainer}>
            <input 
              type="checkbox" 
              checked={isAdmin} 
              onChange={(e) => setIsAdmin(e.target.checked)} 
            />
          </div>
          {isAdmin && (
            <div className={styles.formGroup}>
              <label>Please enter the secret key to create Admin Account:</label>
              <input 
                type="password" 
                placeholder="Admin Password" 
                value={adminPassword} 
                onChange={(e) => setAdminPassword(e.target.value)} 
              />
            </div>
          )}
          <button type="submit">Register</button>
        </form>
        {message && <p className={`${styles.message} ${message.includes('success') ? styles.messageSuccess : styles.messageError}`}>{message}</p>}
      </div>
    </div>
  );
};

export default Register;
