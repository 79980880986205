import React, { useState, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';
import styles from './css/ImportPromptModal.module.css';
import axios from 'axios';

const ImportPromptModal = ({ isOpen, onClose, token, onImport }) => {
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [selectedCombination, setSelectedCombination] = useState('');
  const [newCombinationName, setNewCombinationName] = useState('');

  useEffect(() => {
    const fetchClients = async () => {
      if (isOpen) {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/client-data`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          setClients(response.data);
        } catch (err) {
          console.error("Error fetching clients:", err);
        }
      }
    };

    fetchClients();
  }, [isOpen, token]);

  const handleImport = () => {
    if (!selectedClient || !selectedCombination || !newCombinationName) {
      alert('Please select a client, combination, and provide a new name.');
      return;
    }
    onImport(selectedClient, selectedCombination, newCombinationName);
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    isOpen ? (
      <div className={styles.modalOverlay} onClick={handleOverlayClick}>
        <div className={styles.modalContent}>
          <button className={styles.closeButton} onClick={onClose}>
            <FaTimes />
          </button>
          <h2>Import Prompt Combination</h2>
          <div className={styles.formGroup}>
            <label htmlFor="client">Select Client:</label>
            <select id="client" value={selectedClient} onChange={(e) => setSelectedClient(e.target.value)}>
              <option value="">Select a client</option>
              {clients.map(client => (
                <option key={client.client_name} value={client.client_name}>
                  {client.client_name}
                </option>
              ))}
            </select>
          </div>
          {selectedClient && (
            <div className={styles.formGroup}>
              <label htmlFor="combination">Select Prompt Combination:</label>
              <select id="combination" value={selectedCombination} onChange={(e) => setSelectedCombination(e.target.value)}>
                <option value="">Select a combination</option>
                {clients.find(client => client.client_name === selectedClient)?.prompt_combinations.map(combination => (
                  <option key={combination.combination_id} value={combination.combination_id}>
                    {combination.combination_name}
                  </option>
                ))}
              </select>
            </div>
          )}
          <div className={styles.formGroup}>
            <label htmlFor="newCombinationName">New Combination Name:</label>
            <input
              id="newCombinationName"
              type="text"
              value={newCombinationName}
              onChange={(e) => setNewCombinationName(e.target.value)}
            />
          </div>
          <button className={styles.importButton} onClick={handleImport}>
            Import
          </button>
        </div>
      </div>
    ) : null
  );
};

export default ImportPromptModal;