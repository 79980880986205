import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './TextGeneration.css';  // Import the CSS file
import { useNavigate } from 'react-router-dom';

const TextGeneration = ({ token }) => {
  const [xmlData, setXmlData] = useState(null);
  const [clientName, setClientName] = useState('');
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [unselectedColumns, setUnselectedColumns] = useState([]);
  const [filterRules, setFilterRules] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        setLoading(true);
        const response = await axios.get('${process.env.REACT_APP_API_URL}/config', {
          headers: { Authorization: `Bearer ${token}` },
        });
        const clientName = response.data.client_name;
        setClientName(clientName);
        const xmlUrl = response.data.xml_url;

        // Load XML from the URL
        loadXmlFromUrl(xmlUrl);
      } catch (err) {
        console.error(err);
        setMessage('Failed to fetch configuration');
        setLoading(false);
      }
    };
    fetchConfig();
  }, [token]);

  const loadXmlFromUrl = async (url) => {
    try {
      const response = await fetch('https://europe-west3-quantum-feed-engine.cloudfunctions.net/qfe-xml-viewer-fetcher?url=' + encodeURIComponent(url));
      const xmlText = await response.text();
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(xmlText, "text/xml");
      setXmlData(xmlDoc);
      initializeColumns(xmlDoc);
      displayTable(xmlDoc);
      setMessage('');
      setLoading(false);
    } catch (error) {
      console.error('Error loading XML:', error);
      setMessage('Failed to load XML');
      setLoading(false);
    }
  };

  const initializeColumns = (xmlDoc) => {
    const items = xmlDoc.getElementsByTagName('item') || xmlDoc.getElementsByTagNameNS('*', 'item');
    const predefinedTags = ['id', 'title', 'description', 'product_type', 'brand', 'color', 'material'];
    const seenTags = new Set();
    
    Array.from(items).forEach(itemNode => {
      Array.from(itemNode.children).forEach(child => {
        if (predefinedTags.includes(child.tagName.replace(/^.*:/, ''))) {
          seenTags.add(child.tagName);
        }
      });
    });

    const columns = Array.from(seenTags).map(tag => ({ tag }));
    setUnselectedColumns(columns);
    setSelectedColumns([]);
  };

  const moveToSelected = () => {
    const selectedOptions = Array.from(document.getElementById('unselectedColumns').selectedOptions);
    const selectedTags = selectedOptions.map(option => option.value);

    setSelectedColumns(prevSelected => [
      ...prevSelected,
      ...unselectedColumns.filter(col => selectedTags.includes(col.tag))
    ]);
    setUnselectedColumns(prevUnselected =>
      prevUnselected.filter(col => !selectedTags.includes(col.tag))
    );

    setTimeout(displayTable, 0);
  };

  const moveToUnselected = (index) => {
    setUnselectedColumns(prevUnselected => [
      ...prevUnselected,
      selectedColumns[index]
    ]);
    setSelectedColumns(prevSelected =>
      prevSelected.filter((_, i) => i !== index)
    );

    setTimeout(displayTable, 0);
  };

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData('index', index);
  };

  const handleDrop = (e, index) => {
    const draggedIndex = e.dataTransfer.getData('index');
    const columns = [...selectedColumns];
    const [removed] = columns.splice(draggedIndex, 1);
    columns.splice(index, 0, removed);
    setSelectedColumns(columns);
    setTimeout(displayTable, 0);
  };

  const displayTable = () => {
    if (!xmlData) return;
    
    const items = xmlData.getElementsByTagName('item') || xmlData.getElementsByTagNameNS('*', 'item');
    const rows = Array.from(items).map(item => {
      const row = {};
      selectedColumns.forEach(col => {
        const elements = item.getElementsByTagName(col.tag) || item.getElementsByTagNameNS('*', col.tag);
        if (elements.length > 0) {
          row[col.tag] = Array.from(elements).map(el => el.textContent.trim()).join(', ');
        } else {
          row[col.tag] = 'N/A';
        }
      });
      return row;
    });

    setTableData(rows);
  };

  const addFilterRule = () => {
    setFilterRules([...filterRules, { column: selectedColumns[0].tag, type: 'contains', value: '', conjunction: 'AND' }]);
  };

  const updateFilterRule = (index, property, value) => {
    const updatedRules = [...filterRules];
    updatedRules[index][property] = value;
    setFilterRules(updatedRules);
  };

  const removeFilterRule = (index) => {
    const updatedRules = filterRules.filter((_, i) => i !== index);
    setFilterRules(updatedRules);
  };

  const applyFilters = () => {
    if (!xmlData) return;
    
    const items = xmlData.getElementsByTagName('item') || xmlData.getElementsByTagNameNS('*', 'item');
    const rows = Array.from(items).filter(item => passesFilters(item)).map(item => {
      const row = {};
      selectedColumns.forEach(col => {
        const elements = item.getElementsByTagName(col.tag) || item.getElementsByTagNameNS('*', col.tag);
        if (elements.length > 0) {
          row[col.tag] = Array.from(elements).map(el => el.textContent.trim()).join(', ');
        } else {
          row[col.tag] = 'N/A';
        }
      });
      return row;
    });

    setTableData(rows);
  };

  const passesFilters = (item) => {
    if (!filterRules.length) return true;

    let currentResult = true;
    let overallResult = false;
    let groupStarted = false;

    filterRules.forEach((filter, index) => {
      const elements = Array.from(item.getElementsByTagName(filter.column) || item.getElementsByTagNameNS('*', filter.column));
      const result = elements.some(element => {
        let textContent = element.textContent.trim();

        switch (filter.type) {
          case 'contains':
            return textContent.toLowerCase().includes(filter.value.toLowerCase());
          case 'length=':
            return textContent.length === parseInt(filter.value, 10);
          case 'length>':
            return textContent.length > parseInt(filter.value, 10);
          case 'length<':
            return textContent.length < parseInt(filter.value, 10);
          case 'is equal to':
            return textContent === filter.value;
          case 'is not equal to':
            return textContent !== filter.value;
          case 'starts with':
            return textContent.startsWith(filter.value);
          case 'ends with':
            return textContent.endsWith(filter.value);
          case 'is greater than':
            return parseFloat(textContent) > parseFloat(filter.value);
          case 'is greater or equal to':
            return parseFloat(textContent) >= parseFloat(filter.value);
          case 'is less than':
            return parseFloat(textContent) < parseFloat(filter.value);
          case 'is less or equal to':
            return parseFloat(textContent) <= parseFloat(filter.value);
          case 'is between':
            const [min, max] = filter.value.split('-').map(Number);
            const num = parseFloat(textContent);
            return num >= min && num <= max;
          case 'is not between':
            const [minNot, maxNot] = filter.value.split('-').map(Number);
            const numNot = parseFloat(textContent);
            return numNot < minNot || numNot > maxNot;
          case 'is blank':
            return textContent === '';
          case 'is not blank':
            return textContent !== '';
          case 'matches regexp':
            return new RegExp(filter.value).test(textContent);
          case 'doesn\'t match regexp':
            return !new RegExp(filter.value).test(textContent);
          default:
            return false;
        }
      });

      if (index === 0 || filter.conjunction === 'AND') {
        if (!groupStarted) {
          currentResult = result;
          groupStarted = true;
        } else {
          currentResult = currentResult && result;
        }
      } else if (filter.conjunction === 'OR') {
        overallResult = overallResult || currentResult;
        currentResult = result;
        groupStarted = true;
      }
    });

    overallResult = overallResult || currentResult;
    return overallResult;
  };

  return (
    <div className="text-generation-container">
      <header>
        <img src="https://s3.eu-north-1.amazonaws.com/static.s360digital.com/s360-logo-white-1.svg" alt="s360 Logo" />
        <h1>Feed Text Generation</h1>
        <button onClick={() => navigate('/client')} className="back-button">Back to overview</button>
      </header>

      {loading && (
        <div className="loading-overlay">Importing Current Feed...</div>
      )}

      {message && (
        <div className="loading-overlay">{message}</div>
      )}

      <div className="container">
        <select id="unselectedColumns" className="list" multiple>
          {unselectedColumns.map((col, index) => (
            <option key={index} value={col.tag}>{col.tag}</option>
          ))}
        </select>
        <div className="button-container">
          <button onClick={moveToSelected}> &gt;&gt; </button>
        </div>
        <ul id="selectedColumns" className="list">
          {selectedColumns.map((col, index) => (
            <li
              key={index}
              draggable
              onDragStart={(e) => handleDragStart(e, index)}
              onDragOver={(e) => e.preventDefault()}
              onDrop={(e) => handleDrop(e, index)}
            >
              {col.tag}
              <button className="remove-btn" onClick={() => moveToUnselected(index)}>X</button>
            </li>
          ))}
        </ul>
      </div>

      <div id="filterControls">
        <h2>Filter Configuration</h2>
        <div id="filterRulesContainer">
          {filterRules.map((rule, index) => (
            <div key={index} className="filter-rule">
              {index > 0 && (
                <select value={rule.conjunction} onChange={(e) => updateFilterRule(index, 'conjunction', e.target.value)}>
                  <option value="AND">AND</option>
                  <option value="OR">OR</option>
                </select>
              )}
              <select value={rule.column} onChange={(e) => updateFilterRule(index, 'column', e.target.value)}>
                {selectedColumns.map((col, idx) => (
                  <option key={idx} value={col.tag}>{col.tag}</option>
                ))}
              </select>
              <select value={rule.type} onChange={(e) => updateFilterRule(index, 'type', e.target.value)}>
                <option value="contains">Contains</option>
                <option value="length=">Length =</option>
                <option value="length>">Length &gt;</option>
                <option value="length<">Length &lt;</option>
                <option value="is equal to">Is equal to</option>
                <option value="is not equal to">Is not equal to</option>
                <option value="starts with">Starts with</option>
                <option value="ends with">Ends with</option>
                <option value="is greater than">Is greater than</option>
                <option value="is greater or equal to">Is greater or equal to</option>
                <option value="is less than">Is less than</option>
                <option value="is less or equal to">Is less or equal to</option>
                <option value="is between">Is between</option>
                <option value="is not between">Is not between</option>
                <option value="is blank">Is blank</option>
                <option value="is not blank">Is not blank</option>
                <option value="matches regexp">Matches RegExp</option>
                <option value="doesn't match regexp">Doesn't match RegExp</option>
              </select>
              <input type="text" value={rule.value} onChange={(e) => updateFilterRule(index, 'value', e.target.value)} />
              <button onClick={() => removeFilterRule(index)}>Remove</button>
            </div>
          ))}
        </div>
        <button onClick={addFilterRule}>Add Filter Rule</button>
        <button onClick={applyFilters}>Apply Filters</button>
      </div>

      <table id="content">
        <thead>
          <tr>
            {selectedColumns.map((col, index) => (
              <th key={index}>{col.tag}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, index) => (
            <tr key={index}>
              {selectedColumns.map((col, idx) => (
                <td key={idx}>{row[col.tag]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TextGeneration;
