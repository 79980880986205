import React, { useState, useEffect, useCallback } from 'react';
import { FaTimes } from 'react-icons/fa';
import styles from './css/SaveOptionsModal.module.css';

const SaveOptionsModal = ({ isOpen, onClose, onSave, hasExistingCombination, existingCombinationNames, currentCombinationName }) => {
    const [newCombinationName, setNewCombinationName] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        if (isOpen) {
            setNewCombinationName('');
            setError('');
            document.addEventListener('keydown', handleKeyDown);
            return () => {
                document.removeEventListener('keydown', handleKeyDown);
            };
        }
    }, [isOpen]);

    const handleSave = (overwrite) => {
        if (overwrite) {
            onSave(true, currentCombinationName);  // Pass the current combination name
        } else {
            if (!newCombinationName.trim()) {
                setError('Please provide a name for the new prompt combination.');
                return;
            }
            if (existingCombinationNames.includes(newCombinationName.trim())) {
                setError('A prompt combination with this name already exists. Please choose a different name.');
                return;
            }
            onSave(false, newCombinationName.trim());
        }
    };

    const handleClickOutside = useCallback((e) => {
        if (e.target.id === 'modalOverlay') {
            onClose();
        }
    }, [onClose]);

    const handleKeyDown = useCallback((e) => {
        if (e.key === 'Escape') {
            onClose();
        }
    }, [onClose]);

    if (!isOpen) return null;

    return (
        <div id="modalOverlay" className={styles.modalOverlay} onClick={handleClickOutside}>
            <div className={styles.modalContent}>
                <button className={styles.closeButton} onClick={onClose} aria-label="Close">
                    <FaTimes />
                </button>
                <h2>Save Options</h2>
                <div className={styles.optionsContainer}>
                    {hasExistingCombination && (
                        <button
                            className={styles.button}
                            onClick={() => handleSave(true)}
                        >
                            Overwrite Existing
                        </button>
                    )}
                    <div className={styles.saveNewContainer}>
                        <input
                            type="text"
                            value={newCombinationName}
                            onChange={(e) => {
                                setNewCombinationName(e.target.value);
                                setError('');
                            }}
                            placeholder="Enter new combination name"
                            className={styles.newCombinationNameInput}
                        />
                        <button
                            className={styles.button}
                            onClick={() => handleSave(false)}
                        >
                            Save As New
                        </button>
                    </div>
                </div>
                {error && <div className={styles.error}>{error}</div>}
            </div>
        </div>
    );
};

export default SaveOptionsModal;
