import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import styles from './css/ClientDashboard.module.css';
import settingsIconWhite from './ImagesIcons/settings-white.png';  // Ensure the correct path

const ClientDashboard = ({ token, isAdmin, clientNameProp }) => {
  const [clientName, setClientName] = useState(clientNameProp || '');
  const [overviewData, setOverviewData] = useState({});
  const [usageData, setUsageData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchClientName = async () => {
      if (!clientNameProp) {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/config`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          setClientName(response.data.client_name);
        } catch (err) {
          console.error(err);
        }
      }
    };
    fetchClientName();
  }, [token, clientNameProp]);

  useEffect(() => {
    const fetchOverviewData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/user-details`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setOverviewData(response.data);
      } catch (err) {
        console.error(err);
      }
    };
    fetchOverviewData();
  }, [token]);

  useEffect(() => {
    const fetchUsageData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/credit-usage`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        const sortedData = response.data.usage_data.sort((a, b) => new Date(a.date) - new Date(b.date));
        setUsageData(sortedData);
      } catch (err) {
        console.error(err);
      }
    };
    fetchUsageData();
  }, [token]);

  const handleLogout = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/logout`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
      console.log('Logout successful');
    } catch (err) {
      console.error('Error logging out', err);
    }
    localStorage.removeItem('token');
    navigate('/login');
  };

  return (
    <div className={styles.dashboardContainer}>
      <header className={styles.dashboardHeader}>
        <div className={styles.logoContainer}>
          <img src="https://s3.eu-north-1.amazonaws.com/static.s360digital.com/s360-logo-white-1.svg" alt="s360 Logo" className={styles.dashboardLogo} />
          <span className={styles.logoText}>Quantum Feed Engine</span>
        </div>
        <div className={styles.rightContainer}>
          <div className={styles.welcomeContainer}>
            <small className={styles.welcomeText}>Welcome, {clientName}</small>
            <button onClick={handleLogout} className={styles.logoutBtn}>Logout</button>
          </div>
          {!isAdmin && (
            <button onClick={() => navigate('/config')} className={styles.configBtn}>
              <img src={settingsIconWhite} alt="Settings" className={styles.settingsIcon} />
            </button>
          )}
        </div>
      </header>
      <div className={styles.dashboardContent}>
        <div className={`${styles.contentBox} ${styles.feedManagement}`}>
          <h3>Feed Management</h3>
          <p>Manage your feed with the tools below:</p>
          <button className={styles.dashboardBtn} onClick={() => navigate('/qptext')}>Feed Text Generation</button>
          <button className={`${styles.dashboardBtn} ${styles.disabled}`} onClick={() => navigate('/image-generation')}>Feed Image Generation (In development)</button>
          <button className={`${styles.dashboardBtn} ${styles.disabled}`} onClick={() => navigate('/video-generation')}>Feed Video Generation (In development)</button>
        </div>
        <div className={`${styles.contentBox} ${styles.websiteManagement}`}>
          <h3>Website Management</h3>
          <p>Manage your website with the upcoming tools:</p>
          <button className={`${styles.dashboardBtn} ${styles.disabled}`}>Text Generation (In development)</button>
        </div>
        <div className={`${styles.contentBox} ${styles.overviewContainer}`}>
          <h3>Overview</h3>
          <ul>
            <li>Client Status: <b>{overviewData.client_status}</b></li>
            <li>Credits Per Month: <b>{overviewData.credits_per_month}</b></li>
            <li>Current Credits: <b>{overviewData.current_tokens}</b></li>
            <li>Credit Price: <b>{overviewData.token_price}</b> {overviewData.currency} </li>
          </ul>
        </div>
        <div className={`${styles.contentBox} ${styles.usageChartContainer}`}>
          <h3>Credit Usage Over Time</h3>
          <ResponsiveContainer width="100%" height={400}>
            <LineChart
              data={usageData}
              margin={{
                top: 5, right: 30, left: 20, bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" tickFormatter={(tick) => {
                const date = new Date(tick);
                return `${date.getDate()}/${date.getMonth() + 1}`;
              }} />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="credits_used" stroke="#8884d8" activeDot={{ r: 8 }} />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default ClientDashboard;
